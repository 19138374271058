<template>
  <button
    aria-label="show more"
    class="w-full relative link pb-15 block -mt-40"
  >
    <div
      class="h-40 w-full relative z-10 bg-gradient-to-t from-white to-white/0 via-white/75 flex items-end justify-center border-b border-b-gray-400 mb-10"
    >
      <SpriteSymbol
        name="caret"
        class="w-20 h-6 bg-white -mb-3"
        aria-hidden="true"
      />
    </div>
    {{ $texts('showMore', 'Mehr anzeigen') }}
  </button>
</template>
