<template>
  <div v-if="links.length" class="relative">
    <MenuList>
      <MenuListItem
        v-for="(link, i) in links"
        :key="i"
        :link="link.link"
        :subtree="getSubtreeLinks(link.subtree)"
        heading="h3"
      />
    </MenuList>
    <ButtonShowMore
      v-if="!showAllItems"
      class="md:hidden"
      @click="showAll = true"
    />
  </div>
</template>

<script lang="ts" setup>
import type {
  OrganizationMenuElementFragment,
  OrganizationMenuElementSubtreeFragment,
} from '#graphql-operations'

type MenuOrganizationItem =
  | OrganizationMenuElementFragment
  | OrganizationMenuElementSubtreeFragment

const props = defineProps<{
  links: MenuOrganizationItem[]

  // Skip first level and flatten child items.
  skipFirstLevel?: boolean

  // Limits the number of items shown.
  // Renders a button that when clicked will show all items.
  showMoreEnabled?: boolean
}>()

const showAll = ref(false)

function getSubtreeLinks(
  links:
    | MenuOrganizationItem['subtree']
    | OrganizationMenuElementSubtreeFragment['subtree'],
): OrganizationMenuElementSubtreeFragment['subtree'] {
  // If the prop is set the first level is skipped and its subtree links are
  // shown. This is currently only used on the organizations overview page.
  // The menu there looks like this:
  //
  // - Aussenbeziehungen
  //   - Ämter und Bereiche
  //     - Europa der Regionen, Oberrheinkooperation
  //     - Interkantonale Zusammenarbeit
  //     - Nachbarn in Basel-Landschaft, in Baden-Württemberg und im Elsass
  //   - Weitere Seiten
  //     - Das ist eine weitere Seite
  // - Bau- und Verkehrsdepartement
  //   - Ämter und Bereiche
  //     - Bussen bezahlen
  //     - Hier blitzen wir
  //   - Weitere Seiten
  //     - Tolle Baustellen
  //
  // What we actually want:
  //
  // - Aussenbeziehungen
  //   - Europa der Regionen, Oberrheinkooperation
  //   - Interkantonale Zusammenarbeit
  //   - Nachbarn in Basel-Landschaft, in Baden-Württemberg und im Elsass
  //   - Das ist eine weitere Seite
  // - Bau- und Verkehrsdepartement
  //   - Bussen bezahlen
  //   - Hier blitzen wir
  //   - Tolle Baustellen
  if (props.skipFirstLevel) {
    const first = links[0]
    if (first && 'subtree' in first) {
      return first.subtree
    }
    return []
  }
  return links
}

const showAllItems = computed(() =>
  props.showMoreEnabled && props.links.length > 8 ? showAll.value : true,
)
</script>
